@import "./scss/base";

.onboarding-page {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  padding: 40px 16px;
  color: $white-color;
  .steps-onboarding {
    width: 80%;
    display: flex;
    flex-flow: row;
    gap: 8px;
    justify-content: space-between;
    &__step {
      width: 100%;
      height: 3px;
      display: inline-block;
      background-color: rgba($color: $white-color, $alpha: 0.5);
      &.active {
        background-color: $white-color;
      }
    }
  }
  .status-member {
    display: flex;
    flex-flow: column; 
    gap: 6px;
    width: 100%;
    &__time {
      display: flex;
      flex-flow: column;
      padding: 15px 0;
      .when {
        background: url(../../public/diamante512.png) no-repeat center center;
        background-size: auto 115% !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        font-size: calc(120px + 2vmin);
        font-weight: bold;
        text-shadow: 1px 1px 20px rgba($color: $dark-color, $alpha: 0.5);
        height: 250px;
      }
      .when-text {
        font-size: calc(22px + 2vmin);
        font-weight: normal;
      }
    }
    &__user-info {
      display: flex;
      flex-flow: column;
      gap: 4px;
    }
  }
  .reward-member {
    display: flex;
    flex-flow: column; 
    gap: 0px;
    width: 100%;
    &__time {
      display: flex;
      flex-flow: column;
      //padding: 32px 0;
      .angel-logo {

      }
      .reward-text {
        font-size: calc(22px + 2vmin);
        font-weight: normal;
        margin: 5px auto;
      }
    }
    &__user-info {
      display: flex;
      flex-flow: column;
      gap: 4px;
    }
  }
}