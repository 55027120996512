@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
/* Font Styles Google Poppins*/
.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

/* Font Styles Google Poppins*/
*, :after, :before {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
}

body {
  font: 100% "Poppins", sans-serif;
  color: #fff;
  background-color: #131414;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}
p.lead {
  font-weight: bold;
}

button {
  border: none;
}

.btn {
  padding: 8px 16px;
  line-height: 24px;
  text-align: center;
  border-radius: 8px;
  transition: all 450ms;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}
.btn:hover {
  cursor: pointer;
  text-decoration: none;
}

.btn-small {
  padding: 4px 10px;
  min-height: auto;
}

.btn-full-width {
  width: 100%;
}

.btn-rounded {
  border-radius: 24px;
}

.btn-primary {
  background-color: #fff;
  color: #131414;
}

.btn-grey {
  background-color: rgba(221, 221, 221, 0.3);
  color: #fff;
}

.btn-disabled {
  background-color: rgba(255, 255, 255, 0.2);
  color: #131414;
  pointer-events: none;
}

.navigation-tabs {
  display: flex;
  flex-flow: row;
  gap: 4px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #131414;
}
.navigation-tabs .nav-item {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 4px;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 14px;
  transition: all ease-in-out;
}
.navigation-tabs .nav-item.current, .navigation-tabs .nav-item:hover {
  color: #fff;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 80%;
}

.swiper-slide:nth-child(2n) {
  width: 60%;
}

.swiper-slide:nth-child(3n) {
  width: 40%;
}

.modal-wrap {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
  z-index: 2;
}
.modal-wrap.modal-open {
  display: flex;
}
.modal-wrap .modal-backdrop {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
}
.modal-wrap .modal {
  flex: 1;
  background-color: #616161;
  border-radius: 16px 16px 0 0;
}
.modal-wrap .modal-body {
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: column;
  gap: 8px;
  padding: 8px;
}
.modal-wrap .modal-body .modal-header {
  padding: 8px 16px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}
.modal-wrap .modal-body .modal-header__title {
  flex: 1;
  text-align: center;
  display: block;
}
.modal-wrap .modal-body .modal-header__close {
  display: flex;
  align-items: center;
  padding: 8px;
}
.modal-wrap .modal-body .modal-content {
  display: flex;
  flex-flow: column;
  gap: 16px;
  padding: 16px;
}

.toast-copy {
  display: flex;
  flex-flow: row;
  gap: 8px;
  align-items: center;
}

.home-page {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  padding: 0;
  padding-bottom: 76px;
  color: #fff;
  width: 100%;
}
.home-page .page-content {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
}
.home-page .page-content .banner-score {
  padding: 16px 16px;
  background: url(../../public/banner-bg.jpg) no-repeat center center;
  background-size: cover;
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-flow: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  position: sticky;
  top: 0px;
  z-index: 2;
}
.home-page .page-content .status-member {
  width: 100%;
  padding: 0 16px;
}
.home-page .page-content .status-member__info {
  margin-bottom: 32px;
}
.home-page .page-content .status-member__info img {
  width: 175px;
}
.home-page .page-content .status-member__info .reward-points {
  display: flex;
  flex-flow: column;
  gap: 4px;
  font-size: 24px;
  font-weight: bold;
}
.home-page .page-content .status-member__info .reward-points span {
  font-size: 14px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
}
.home-page .page-content .angel-swiper {
  width: 100%;
  overflow: hidden;
}
.home-page .page-content .angel-swiper .swiper {
  width: calc(100% - 32px);
  overflow: visible;
}
.home-page .page-content .angel-swiper .swiper .swiper-wrapper {
  height: auto;
}
.home-page .page-content .angel-swiper .swiper .swiper-wrapper .swiper-slide {
  border-radius: 8px;
  background-color: #2d2d2d;
  width: calc(100% - 16px);
  padding: 16px;
  display: flex;
  flex-flow: column;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
}
.home-page .page-content .angel-swiper .swiper .swiper-wrapper .swiper-slide h5 {
  font-size: 16px;
}
.home-page .page-content .angel-swiper .swiper .swiper-wrapper .swiper-slide .btn-container {
  text-align: right;
  width: 100%;
}
.home-page .page-content .angel-swiper .swiper .swiper-wrapper .swiper-slide .btn-container .btn {
  font-size: 14px;
}
.home-page .page-content .angel-swiper .swiper .swiper-wrapper .swiper-slide .desc {
  text-align: left;
}
.home-page .page-content .angel-swiper .swiper .swiper-pagination {
  margin-top: 8px;
  position: inherit;
}
.home-page .page-content .angel-swiper .swiper .swiper-pagination .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.5);
}
.home-page .page-content .angel-swiper .swiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
}
.home-page .page-content .tasks {
  display: flex;
  flex-flow: column;
  gap: 16px;
  justify-content: flex-start;
  padding: 0 16px;
}
.home-page .page-content .tasks .new-points-popup {
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
  height: 100%;
  background-color: #14222f;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../public/party.gif);
  width: 100%;
}
.home-page .page-content .tasks .new-points-popup .content {
  width: 100%;
}
.home-page .page-content .tasks .new-points-popup .content > img {
  width: 100%;
}
.home-page .page-content .tasks .new-points-popup .content > span {
  z-index: 9;
  text-align: ce;
}
.home-page .page-content .tasks__title {
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
.home-page .page-content .tasks__item {
  display: flex;
  flex-flow: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}
.home-page .page-content .tasks__item-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}
.home-page .page-content .tasks__item-icon > img {
  width: 20px;
}
.home-page .page-content .tasks__item-text {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
}
.home-page .page-content .tasks__item-text__name {
  font-weight: bold;
  text-align: left;
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.home-page .page-content .tasks__item-text__amount {
  color: rgba(255, 255, 255, 0.5);
}
.home-page .page-content .tasks__item .btn {
  font-size: 12px;
}
.home-page .page-content .rewards {
  display: flex;
  flex-flow: column;
  gap: 16px;
  justify-content: flex-start;
  padding: 32px 16px;
}
.home-page .page-content .rewards__title {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  justify-content: center;
}
.home-page .page-content .rewards__title-section {
  flex: 1;
  text-align: left;
}
.home-page .page-content .rewards__title-token {
  flex: 1;
  text-align: right;
}
.home-page .page-content .rewards__item {
  display: flex;
  flex-flow: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}
.home-page .page-content .rewards__item-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}
.home-page .page-content .rewards__item-icon > img {
  width: 20px;
}
.home-page .page-content .rewards__item-text {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
}
.home-page .page-content .rewards__item-text__name {
  font-weight: bold;
  text-align: left;
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.home-page .page-content .rewards__item-amount {
  font-size: 14px;
  color: white;
}
.home-page .page-content .rewards__item .btn {
  font-size: 12px;
}

/* HTML: <div class="heard-loader"></div> */
.heard-loader {
  width: 200px;
  margin: 50px auto;
  aspect-ratio: 1;
  color: #dc1818;
  background: radial-gradient(circle at 60% 65%, currentColor 62%, rgba(0, 0, 0, 0) 65%) top left, radial-gradient(circle at 40% 65%, currentColor 62%, rgba(0, 0, 0, 0) 65%) top right, linear-gradient(to bottom left, currentColor 42%, rgba(0, 0, 0, 0) 43%) bottom left, linear-gradient(to bottom right, currentColor 42%, rgba(0, 0, 0, 0) 43%) bottom right;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
}

.heard-loader:after {
  content: "";
  position: absolute;
  inset: 0;
  background: inherit;
  opacity: 0.4;
  animation: l3 1s infinite;
}

@keyframes l3 {
  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

