@import "./scss/base";

.home-page {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    padding: 0;
    padding-bottom: 76px;
    color: $white-color;
    width: 100%;
    .page-content {
        flex: 1;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        gap: 16px;
        width: 100%;
        .banner-score {
            padding: 16px 16px;
            background: url(../../public/banner-bg.jpg) no-repeat center center;
            background-size: cover;
            width: 100%;
            font-size: 14px;
            display: flex;
            flex-flow: row;
            gap: 4px;
            justify-content: center;
            align-items: center;
            color: $white-color;
            text-decoration: none;
            position: sticky;
            top: 0px;
            z-index: 2;
        }
        .status-member {
            width: 100%;
            padding: 0 16px;
            &__info {
                img {
                    width: 175px;
                }
                .reward-points {
                    display: flex;
                    flex-flow: column;
                    gap: 4px;
                    font-size: 24px;
                    font-weight: bold;
                    span {
                        font-size: 14px;
                        font-weight: bold;
                        color: rgba($color: $white-color, $alpha: 0.5);
                    }
                }
                margin-bottom: 32px;
            }        
        }
        .angel-swiper {
            width: 100%;
            overflow: hidden;
            .swiper {
                width: calc(100% - 32px);
                overflow: visible;
                .swiper-wrapper {
                    height: auto;
                    .swiper-slide {
                        border-radius: 8px;
                        background-color: #2d2d2d;
                        width: calc(100% - 16px);
                        padding: 16px;
                        display: flex;
                        flex-flow: column;
                        gap: 8px;
                        justify-content: flex-start;
                        align-items: flex-start;
                        font-size: 14px;
                        h5 {
                            font-size: 16px;
                        }
                        .btn-container {
                            text-align: right;
                            width: 100%;
                            .btn {
                                font-size: 14px;
                            }
                        }
                        .desc {
                            text-align: left;
                        }
                    }
                }
                .swiper-pagination {
                    margin-top: 8px;
                    position: inherit;
                    .swiper-pagination-bullet {
                        background-color: rgba($color: $white-color, $alpha: 0.5)
                    }
                    .swiper-pagination-bullet-active {
                        background-color: $white-color;
                    }
                }
            }
        }
        .tasks {
            display: flex;
            flex-flow: column;
            gap: 16px;
            justify-content: flex-start;
            padding: 0 16px;
            .new-points-popup {
                position: fixed;
                top: 0;
                z-index: 10;
                left: 0;
                height: 100%;
                background-color: #14222f;
                display: flex;
                justify-content: center;
                align-items: center;
                background: url(../../public/party.gif);
                width: 100%;
                .content {
                    width: 100%;
                    > img {
                        width: 100%;
                    }
                    > span {
                        z-index: 9;
                        text-align: ce;

                    }
                }
            }
            &__title {
                width: 100%;
                text-align: left;
                font-size: 16px;
                font-weight: bold;
            }
            &__item {
                display: flex;
                flex-flow: row;
                gap: 8px;
                justify-content: space-between;
                align-items: center;
                &-icon {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background-color: rgba($color: $white-color, $alpha: 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 4px;
                    > img {
                        width: 20px;
                    }
                }
                &-text {
                    flex: 1;
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    font-size: 12px;
                    &__name {
                        font-weight: bold;
                        text-align: left;
                        max-height: 36px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    &__amount {
                        color: rgba($color: $white-color, $alpha: 0.5);
                    }
                }
                .btn {
                    font-size: 12px;
                }
            }
        }
        .rewards {
            display: flex;
            flex-flow: column;
            gap: 16px;
            justify-content: flex-start;
            padding: 32px 16px;
            &__title {
                width: 100%;
                font-size: 16px;
                font-weight: bold;
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                justify-content: center;
                &-section {
                    flex: 1;
                    text-align: left;
                }
                &-token {
                    flex: 1;
                    text-align: right;
                }
            }
            &__item {
                display: flex;
                flex-flow: row;
                gap: 8px;
                justify-content: space-between;
                align-items: center;
                &-icon {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background-color: rgba($color: $white-color, $alpha: 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 4px;
                    > img {
                        width: 20px;
                    }
                }
                &-text {
                    flex: 1;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    font-size: 12px;
                    &__name {
                        font-weight: bold;
                        text-align: left;
                        max-height: 36px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
                &-amount {
                    font-size: 14px;
                    color: rgba($color: $white-color, $alpha: 1.0);
                }
                .btn {
                    font-size: 12px;
                }
            }
        }
    }
}

/* HTML: <div class="heard-loader"></div> */
.heard-loader {
    width: 200px;
    margin: 50px auto;
    aspect-ratio: 1;
    color:#dc1818;
    background:
        radial-gradient(circle at 60% 65%, currentColor 62%, #0000 65%) top left,
        radial-gradient(circle at 40% 65%, currentColor 62%, #0000 65%) top right,
        linear-gradient(to bottom left, currentColor 42%,#0000 43%) bottom left ,
        linear-gradient(to bottom right,currentColor 42%,#0000 43%) bottom right;
    background-size: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
}
.heard-loader:after {
    content: "";
    position: absolute;
    inset: 0;
    background: inherit;
    opacity: 0.4;
    animation: l3 1s infinite;
}
@keyframes l3 {
    to {transform:scale(1.8);opacity:0}
}