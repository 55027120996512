@import "./scss/base";

.leaderboard-page {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    padding: 0;
    padding-bottom: 76px;
    color: $white-color;
    width: 100%;
    .page-content {
        flex: 1;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        gap: 16px; 
        width: 100%;
        .page-content__head {
            position: sticky;
            top: 0;
            background-color: $dark-color;
            border: none;
            display: flex;
            flex-flow: column;
            gap: 32px;
            justify-content: flex-start;
            width: 100%;
            padding: 16px;
            &-title {
                font-size: 24px;
            }
            &-info {
                display: flex;
                flex-flow: row;
                align-items: center;
                gap: 8px;
                width: 100%;
                padding: 16px;
                background-color: rgba($color: $white-color, $alpha: 0.2);
                border-radius: 8px;
                .info-avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background-color: $white-color;
                    color: $dark-color;
                    padding: 4px;
                    font-size: 14px;
                }
                .info-user {
                    flex: 1;
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    font-size: 14px;
                    &__name {
                        font-weight: bold;
                        text-overflow: ellipsis;
                    }
                    &__tokens {
                        color: rgba($color: $white-color, $alpha: 0.5);
                    }
                }
                .info-position {
                    font-size: 14px;
                }
            }
        }
        .holders {
            padding: 16px;
            display: flex;
            flex-flow: column;
            gap: 16px;
            justify-content: flex-start;
            &__title {
                width: 100%;
                text-align: left;
                font-size: 16px;
                font-weight: bold;
            }
            &__item {
                display: flex;
                flex-flow: row;
                gap: 8px;
                justify-content: space-between;
                align-items: center;
                &-avatar {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background-color: rgba($color: $white-color, $alpha: 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 4px;
                    font-size: 14px;
                }
                &-text {
                    flex: 1;
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    font-size: 12px;
                    &__name {
                        font-weight: bold;
                        text-align: left;
                        max-height: 36px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                    &__amount {
                        color: rgba($color: $white-color, $alpha: 0.5);
                    }
                }
                .holder-position {
                    font-size: 14px;
                    &__position {
                        &.gold-medal {
                            color: gold;
                        }
                        &.silver-medal {
                            color: silver;
                        }
                        &.bronze-medal {
                            color: #CD7F32;
                        }
                    }
                }
            }
        }
    }
}