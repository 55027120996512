@import "./scss/base";

.error-page {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: space-around;
    font-size: calc(10px + 2vmin);
    padding: 16px;
    padding-bottom: 76px;
    color: $white-color;
    gap: 32px;
    h1 {
        font-size: 28px;
        text-align: center;
    }
}