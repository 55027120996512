@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
/* Font Styles Google Poppins*/
.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

/* Font Styles Google Poppins*/
*, :after, :before {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
}

body {
  font: 100% "Poppins", sans-serif;
  color: #fff;
  background-color: #131414;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}
p.lead {
  font-weight: bold;
}

button {
  border: none;
}

.btn {
  padding: 8px 16px;
  line-height: 24px;
  text-align: center;
  border-radius: 8px;
  transition: all 450ms;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}
.btn:hover {
  cursor: pointer;
  text-decoration: none;
}

.btn-small {
  padding: 4px 10px;
  min-height: auto;
}

.btn-full-width {
  width: 100%;
}

.btn-rounded {
  border-radius: 24px;
}

.btn-primary {
  background-color: #fff;
  color: #131414;
}

.btn-grey {
  background-color: rgba(221, 221, 221, 0.3);
  color: #fff;
}

.btn-disabled {
  background-color: rgba(255, 255, 255, 0.2);
  color: #131414;
  pointer-events: none;
}

.navigation-tabs {
  display: flex;
  flex-flow: row;
  gap: 4px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #131414;
}
.navigation-tabs .nav-item {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 4px;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 14px;
  transition: all ease-in-out;
}
.navigation-tabs .nav-item.current, .navigation-tabs .nav-item:hover {
  color: #fff;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 80%;
}

.swiper-slide:nth-child(2n) {
  width: 60%;
}

.swiper-slide:nth-child(3n) {
  width: 40%;
}

.modal-wrap {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
  z-index: 2;
}
.modal-wrap.modal-open {
  display: flex;
}
.modal-wrap .modal-backdrop {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
}
.modal-wrap .modal {
  flex: 1;
  background-color: #616161;
  border-radius: 16px 16px 0 0;
}
.modal-wrap .modal-body {
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: column;
  gap: 8px;
  padding: 8px;
}
.modal-wrap .modal-body .modal-header {
  padding: 8px 16px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}
.modal-wrap .modal-body .modal-header__title {
  flex: 1;
  text-align: center;
  display: block;
}
.modal-wrap .modal-body .modal-header__close {
  display: flex;
  align-items: center;
  padding: 8px;
}
.modal-wrap .modal-body .modal-content {
  display: flex;
  flex-flow: column;
  gap: 16px;
  padding: 16px;
}

.toast-copy {
  display: flex;
  flex-flow: row;
  gap: 8px;
  align-items: center;
}

.error-page {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  padding: 16px;
  padding-bottom: 76px;
  color: #fff;
  gap: 32px;
}
.error-page h1 {
  font-size: 28px;
  text-align: center;
}

